<template>
  <div class="materials__categories">
    <router-link exact class="materials__category" :to="{ name: 'RecommendedEvents' }">
      Все
    </router-link>
    <router-link
      exact
      class="materials__category"
      :to="{ name: 'RecommendedEventsDetail', params: { type: 'active' } }"
      v-if="!notEmptyTabs || notEmptyTabs.includes('active')"
    >
      Предстоящие мероприятия
    </router-link>
    <router-link
      exact
      class="materials__category"
      :to="{ name: 'RecommendedEventsDetail', params: { type: 'passed' } }"
      v-if="!notEmptyTabs || notEmptyTabs.includes('passed')"
    >
      Архив мероприятий
    </router-link>
  </div>
</template>

<script>
export default {
  name: "MaterialsCategories",
  props: {
    notEmptyTabs: Array
  }
};
</script>

<style lang="scss" scoped>
.materials {
  &__categories {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    max-width: 100%;
    @media screen and (max-width: 1220px) {
      padding-bottom: 16px;
    }
  }

  &__category {
    padding: 4px 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3c4242;
    border: 1px solid #d8dada;
    border-radius: 99px;
    margin-right: 8px;
    white-space: nowrap;

    &.router-link-active {
      color: #830051;
      border-color: #830051;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>